import React, { useEffect, useState } from 'react'
import Cookies from 'universal-cookie'

// Components
import Layout from '../components/layout'
import SEO from '../components/seo'

// Accent first, background second
const colours = [
  ['#28cf52', '#06160a'],
  ['#E6E7E8', '#050505'],
  ['#FEE715FF', '#101820FF'],
  ['#EC4D37', '#1D1B1B'],
  ['#2a2927', '#e7dfd6'],
]

const getColour = () => {
  const cookies = new Cookies()
  const colour = cookies.get('colour')
  if (colour) {
    return colour
  } else {
    return 4
  }
}

const EasterEgg = () => {
  let canvas
  let canvasContext
  let fps = null
  let paused = true

  let ballX = 50
  let ballY = 50
  let ballSpeedX = 5
  let ballSpeedY = 2.5

  let p1Y = 250
  let p2Y = 250

  let score = {
    win: 5,
    p1: 0,
    p2: 0,
  }

  const BALL_RADIUS = 5
  const PADDLE_HEIGHT = 100
  const PADDLE_GUTTER = 10
  const PADDLE_WIDTH = 10

  function handleClick() {
    if (!paused) return

    score.p1 = 0
    score.p2 = 0
    paused = false

    gameTick()
  }

  function setPlayerPaddlePos(mousePosition) {
    let newPos = mousePosition.y - PADDLE_HEIGHT * 0.5

    // if postion + half paddle height > canvas, stick paddle to bottom
    if (mousePosition.y + PADDLE_HEIGHT * 0.5 >= canvas.height) {
      newPos = canvas.height - PADDLE_HEIGHT
    }
    // if newPos is at top, stick to top
    else if (newPos <= 0) {
      newPos = 0
    }

    p1Y = newPos
  }

  function mousePosFromEvt(evt) {
    const rect = canvas.getBoundingClientRect()
    const root = document.documentElement
    const mouseX = evt.clientX - rect.left - root.scrollLeft
    const mouseY = evt.clientY - rect.top - root.scrollTop

    return { y: mouseY, x: mouseX }
  }

  function ballReset() {
    ballX = canvas.width / 2
    ballY = canvas.height / 2
    ballSpeedX *= -1
    ballSpeedY = ballSpeedY > 0 ? 2.5 : -2.5

    if (score.p1 === score.win || score.p2 === score.win) {
      paused = true
    }
  }

  function animate() {
    if (fps) {
      setTimeout(gameTick, 1000 / fps)
    } else {
      requestAnimationFrame(gameTick)
    }
  }

  function gameTick() {
    drawEverything()
    if (paused) return
    moveEverything()

    // tick
    animate()
  }

  function drawEverything() {
    const mainColour = window.mainColour
    const backgroundColour = window.backgroundColour
    // clear canvas
    colourRect(0, 0, canvas.width, canvas.height, backgroundColour)

    //draw human paddle
    colourRect(PADDLE_GUTTER, p1Y, PADDLE_WIDTH, PADDLE_HEIGHT, mainColour)

    // draw AI paddle
    colourRect(
      canvas.width - PADDLE_GUTTER - PADDLE_WIDTH,
      p2Y,
      PADDLE_WIDTH,
      PADDLE_HEIGHT,
      mainColour
    )

    if (!paused) {
      // draw net
      for (let i = 0; i < canvas.height; i += Math.floor(canvas.height / 6)) {
        const height = Math.floor(canvas.height / 6)
        const offset = height * 0.33
        colourRect(canvas.width / 2 - 2.5, i + offset / 2, 4, height - offset, mainColour)
      }
    }

    // draw score
    canvasContext.fillStyle = mainColour
    canvasContext.font = '24px monospace'
    canvasContext.textAlign = 'center'
    canvasContext.textBaseline = 'middle'
    const scoreText = `${score.p1} : ${score.p2}`
    const scoreTextDimensions = canvasContext.measureText(scoreText)
    canvasContext.fillText(
      scoreText,
      canvas.width / 2,
      10 + scoreTextDimensions.actualBoundingBoxAscent
    )

    if (paused) {
      canvasContext.fillStyle = mainColour
      canvasContext.font = '24px monospace'
      canvasContext.textAlign = 'center'
      canvasContext.textBaseline = 'middle'
      var screenText = ''
      if (score.p1 === score.win) {
        screenText = `You win!`
      } else if (score.p2 === score.win) {
        screenText = `Computer won :(`
      } else {
        screenText = `Click to Play`
      }
      const screenTextDimensions = canvasContext.measureText(screenText)
      canvasContext.fillText(
        screenText,
        canvas.width / 2,
        canvas.height / 2 - screenTextDimensions.actualBoundingBoxAscent
      )
      return
    }

    // draw ball
    colourCircle(ballX, ballY, BALL_RADIUS, '#00C2CC')
  }

  function moveEverything() {
    ballX += ballSpeedX
    ballY += ballSpeedY

    // player paddle
    if (ballX <= PADDLE_GUTTER + PADDLE_WIDTH + BALL_RADIUS && didBallHitPaddle(p1Y)) {
      const deltaY = ballY - (p1Y + PADDLE_HEIGHT / 2)
      ballSpeedX *= -1
      ballSpeedY = deltaY * 0.15
    } else if (
      ballX >= canvas.width - PADDLE_GUTTER - PADDLE_WIDTH - BALL_RADIUS &&
      didBallHitPaddle(p2Y)
    ) {
      const deltaY = ballY - (p2Y + PADDLE_HEIGHT / 2)
      ballSpeedX *= -1
      ballSpeedY = deltaY * 0.15
    } else if (ballX <= PADDLE_GUTTER) {
      score.p2++
      ballReset()
    } else if (ballX >= canvas.width - PADDLE_GUTTER) {
      score.p1++
      ballReset()
    }

    // update ballY
    if (ballY >= canvas.height || ballY <= 0) ballSpeedY *= -1

    // upate AI paddle
    aiMovement()
  }

  function aiMovement() {
    const p2YCenter = p2Y + PADDLE_HEIGHT / 2

    if (p2Y <= 0) p2Y = 0
    if (p2YCenter + PADDLE_HEIGHT / 2 >= canvas.height) p2Y = canvas.height - PADDLE_HEIGHT

    if (p2YCenter < ballY - 15) p2Y += 2.5
    else if (p2YCenter > ballY + 15) p2Y -= 2.5
  }

  function didBallHitPaddle(paddle) {
    return ballY > paddle && ballY < paddle + PADDLE_HEIGHT
  }

  function colourRect(x, y, w, h, colour) {
    canvasContext.fillStyle = colour
    canvasContext.fillRect(x, y, w, h)
  }

  function colourCircle(x, y, r, colour) {
    canvasContext.fillStyle = colour
    canvasContext.beginPath()
    canvasContext.arc(
      x, // x pos
      y, // y position
      r, // radius
      0, // start radian
      2 * Math.PI // end radian
    )
    canvasContext.fill()
  }

  const startPong = () => {
    canvas = document.querySelector('.pong-canvas')
    canvasContext = canvas.getContext('2d')
    ballX = canvas.width / 2
    ballY = canvas.height / 2
    window.addEventListener('mousemove', function (evt) {
      setPlayerPaddlePos(mousePosFromEvt(evt))
    })
    window.addEventListener('click', handleClick)

    gameTick()
  }

  const [canvasHeight, setCanvasHeight] = useState(0)
  useEffect(() => {
    // Wait for colour to be set before starting game
    const currentColour = getColour()
    window.mainColour = colours[currentColour][0]
    window.backgroundColour = colours[currentColour][1]
    startPong()
    setCanvasHeight(window.innerHeight - 162 + 'px')
  })

  return (
    <>
      <Layout page="surprise">
        <SEO title="Surprise!" />
        <section className="easter-egg">
          <canvas
            width="800"
            height={canvasHeight}
            cursor-class="hide"
            className="pong-canvas"
          ></canvas>
        </section>
      </Layout>
    </>
  )
}

export default EasterEgg
